import { createReduxResource } from 'core/redux/resources';
import api from 'core/api';
import {assignChildrenToParents} from "../../core/utils";

export const aiSettingsResource = createReduxResource({
  name: 'aiSettings',

  customOperations: {
    loadChecklistDefinitions: {
      callEndpoint: api.getCommunicationByStatus,
      onSuccess: ({ response }) => {
        return response.body;
      }
    },
    startAiSettings: {
      callEndpoint: api.startAiSettings,
      onSuccess: ({ response }) => {
        return response.body;
      }
    },
    stopAiSettings: {
      callEndpoint: api.stopAiSettings,
      onSuccess: ({ response }) => {
        return response.body;
      }
    },
    exportCommunicationTablePointwise: {
      callEndpoint: api.exportCommunicationTablePointwise,
      onSuccess: ({ response }) => {
        return response.body;
      }
    },
    getCheckStatusDownloadFile: {
      callEndpoint: async requestOptions => {
        const { request_id } = requestOptions;
        return api.getCheckStatusDownloadFileEndpoint({ requestId: request_id });
      },
      onSuccess: ({ response }) => {
        return response.body;
      }
    }
  },
  reactors: {
    onLoadSucceed: ({ resources, dispatch, getState, actions }) => {
      const userCustomFieldsWithChildren = assignChildrenToParents({
        nodesByIds: resources
      });
      return dispatch(actions.loadSucceed(userCustomFieldsWithChildren));
    }
  },
  additionalReducer: {
    loadSucceed: (state, { payload }) => ({
      ...state,
      loading: false,
      byIds: { ...payload }
    })
  }
});
